// DON'T FORMAT ON SAVE. TRAILING SPACES ARE BEING USED FOR LINE BREAKS

export const emailTemplateForIntroducingTowerHunt = {
  order: 2,
  name: "Email template for introducing Tower Hunt",
  subcategory: "Getting started for Tower Hunt admins",
  markdown: `# Email template for introducing Tower Hunt
  Tower Hunt is most valuable when you use it to trade datapoints with teammates and industry peers just like you would in real life. Datapoints in Tower Hunt connect to the rest of your private and public database to surface relationships that are hard to see in email, spreadsheets, and your written notes. We've put together a customizable email template that you can send to your team to help get them up and running.

  ## Instructions

  {% tablessContainer %}
  1. Copy the template below and paste it into a new email message.
  2. Customize the **[placeholder text]**.
  3. Add additional context to help your team understand how and why you're adopting Tower Hunt.
  4. Remove any content that isn't relevant for your team.
  5. {% inlineRouterLink articleId="getting-started-for-team-leaders" %}Create and customize a Tower Hunt team{% /inlineRouterLink %} for your company
  6. Send the email to your team
  7. Invite your teammates to the Tower Hunt team, which triggers invitation emails.
  {% /tablessContainer %}

  ## Email template

  {% tablessContainer paddingStyles="px-6" %}
  Hi team,

  As some of you know, we're starting to use Tower Hunt to do more with our in-house data. Tower Hunt is a database where we can safely link what we know to asset-level data that has been contributed and validated by other professionals in our market. It also enables us to monetize contributions that we choose to make available to others.

  ###### **Why we're using Tower Hunt**
  We work hard to track our markets and **[add your company's key competencies here]**, and we see an opportunity to make the things we learn more actionable for everyone on the team.  Our data is a valuable asset, and Tower Hunt lets us leverage it in new ways.

  ###### **How to get started**
  **Step 1:** Check your inbox for an email from Tower Hunt **[make sure you invited people to the Tower Hunt team]**  
  **Step 2:** Click **Get Started**.  
  **Step 3:** Enter your full name and a password, then click **Join**.  
  **Step 4:** Check out the {% inlineRouterLink articleId="getting-started-for-new-tower-hunt-users" %}getting started guide{% /inlineRouterLink %} and learn {% inlineRouterLink articleId="how-to-use-tower-hunt-your-quick-start-guide" %}how to use Tower Hunt{% /inlineRouterLink %}.

  ###### **Questions of feedback?**
  We encourage you to spend some time exploring Tower Hunt on your own. If you have questions, please send an email to **[your email address here]**. If you have general questions about Tower Hunt, their {% inlineRouterLink %}Help Center{% /inlineRouterLink %} is a great resource.

  **[Fill in with any other next steps if applicable]**

  **[Your name]**
  {% /tablessContainer %}`,
};
